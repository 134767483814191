<script context="module" lang="ts">
  // using whole strings instead of interpolating prevents tailwind from pruning these
  const BREAKPOINT_CLASSES = {
    always: "not-sr-only ",
    large: "sr-only large:not-sr-only",
    never: "sr-only",
    normal: "sr-only normal:not-sr-only",
    small: "sr-only small:not-sr-only",
  }
  type Breakpoint = keyof typeof BREAKPOINT_CLASSES
</script>

<script lang="ts">
  import userAnalytics from "/@/util/user-analytics"

  export let icon = ""
  export let srBreakpoint: Breakpoint = "normal"
  export let userAnalyticsTag = ""
  export let cssClass = ""
  export { cssClass as class }
  export let labelClass = ""
  export let after = false
  export let title = undefined
  export let disabled = false
  export let id = undefined
</script>

<button
  class="icon-button button flex items-center gap-extra-small bg-transparent text-general-primary-label shadow-none tw-icon-{icon} {cssClass}"
  class:flex-row-reverse={after}
  {title}
  {disabled}
  {id}
  use:userAnalytics={userAnalyticsTag}
  on:click
  on:pointerup
>
  <span
    class="label inline-block focus:not-sr-only {BREAKPOINT_CLASSES[
      srBreakpoint
    ]} {labelClass}"
  >
    <slot />
  </span>
</button>
